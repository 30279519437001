import React from 'react';
import {Route, Redirect} from 'react-router-dom';

export default function PrivateRoute({
  // @ts-ignore
  component: Component,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'renderFunction' implicitly has an... Remove this comment to see the full error message
  render: renderFunction,
  ...rest
}) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let isAuthenticated = true;
  let redirect: $TSFixMe = null;
  if (
    (sessionStorage.getItem('Firebase-Authorization') === null ||
      localStorage.getItem('isAnonymous')) &&
    !sessionStorage.getItem('signInSuccessUrl') &&
    !urlSearchParams.get('f')
  ) {
    isAuthenticated = false;
    if (urlSearchParams.get('redirect') !== null) {
      redirect = `${window.location.pathname}${window.location.search}`;
    } else {
      if (urlSearchParams.get('migrate') !== null) {
        localStorage.setItem('bidmiiProUid', window.location.pathname);
        redirect = '/login';
      } else {
        redirect = `${window.location.pathname}${window.location.search}`;
        const encodedURI = encodeURIComponent(redirect);
        redirect = `/login?redirect=${encodedURI}`;
      }
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated === true) {
          if (renderFunction) {
            return renderFunction({...props});
          } else if (Component) {
            return <Component {...props} />;
          }
          throw new Error(
            'Must supply either component or render function to ProtectedRoute',
          );
        } else {
          return <Redirect to={redirect} />;
        }
      }}
    />
  );
}
